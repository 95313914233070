<script lang="ts" setup>
const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: '#60a5fa',
  },
  size: {
    type: String,
    default: '15px',
  },
  margin: {
    type: String,
    default: '2px',
  },
  radius: {
    type: String,
    default: '100%',
  },
});

const spinnerStyle = {
  backgroundColor: props.color,
  height: props.size,
  width: props.size,
  margin: props.margin,
  borderRadius: props.radius,
  display: 'inline-block',
  animationName: 'v-syncStretchDelay',
  animationDuration: '0.6s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  animationFillMode: 'both',
};

const spinnerDelay1 = {
  animationDelay: '0.07s',
};
const spinnerDelay2 = {
  animationDelay: '0.14s',
};
const spinnerDelay3 = {
  animationDelay: '0.21s',
};
</script>

<template>
  <div
    v-show="loading"
    class="v-spinner"
  >
    <div
      class="v-sync v-sync1"
      :style="[spinnerStyle, spinnerDelay1]"
    />
    <div
      class="v-sync v-sync2"
      :style="[spinnerStyle, spinnerDelay2]"
    />
    <div
      class="v-sync v-sync3"
      :style="[spinnerStyle, spinnerDelay3]"
    />
  </div>
</template>

<style>
@-webkit-keyframes v-syncStretchDelay {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes v-syncStretchDelay {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
